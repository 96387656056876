import React from 'react';
import logo from './logo.png';
import './App.css';
import Menu, { CategoryEnum } from './Menu';

function TableGames() {
  return (
    <div id="__nuxt">
      <div id="__layout">
        <div class="wrap nav-close">
          <div class="main">
            <div data-v-16b3034c="" class="gamelist-wrap">
              <div data-v-16b3034c="" class="gamelist-header">
                <div data-v-16b3034c="" class="gamelist-title">
                  <div data-v-16b3034c="" class="title-logo">
                    <img data-v-16b3034c="" src={logo} />
                    <span>UMU</span>
                  </div>
                  <Menu activeCategory={CategoryEnum.CASINO} />
                </div>
              </div>
              <div data-v-16b3034c="" class="gamelist-content">
                <ul data-v-16b3034c="" class="game-list">
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5802&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652302341_718891.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=7808&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652302776_542805.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=7803&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652302975_412244.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                </ul>
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableGames;
