import React from 'react';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';
import HotGames from './HotGames';
import FishGames from './FishGames';
import SlotGames from './SlotGames';
import ArcadeGames from './ArcadeGames';
import TableGames from './TableGames';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/fish-games" Component={FishGames} />
        <Route path="/slot-games" Component={SlotGames} />
        <Route path="/arcade-games" Component={ArcadeGames} />
        <Route path="/casino-games" Component={TableGames} />
        <Route path="*" Component={HotGames} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
