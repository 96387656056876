import React from 'react';
import logo from './logo.png';
import './App.css';
import Menu, { CategoryEnum } from './Menu';

function FishGames() {
  return (
    <div id="__nuxt">
      <div id="__layout">
        <div class="wrap nav-close">
          <div class="main">
            <div data-v-16b3034c="" class="gamelist-wrap">
              <div data-v-16b3034c="" class="gamelist-header">
                <div data-v-16b3034c="" class="gamelist-title">
                  <div data-v-16b3034c="" class="title-logo">
                    <img data-v-16b3034c="" src={logo} />
                    <span>UMU</span>
                  </div>
                  <Menu activeCategory={CategoryEnum.FISH} />
                </div>
              </div>
              <div data-v-16b3034c="" class="gamelist-content">
                <ul data-v-16b3034c="" class="game-list">
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5702&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1695380793_711640.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=7707&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652300189_424210.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=7701&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299550_797150.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=7706&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652300016_704100.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=3721&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299633_273627.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=3727&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299671_953107.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=7705&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299591_156934.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                </ul>
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default FishGames;
