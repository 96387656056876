import React from 'react';
import logo from './logo.png';
import './App.css';
import Menu, { CategoryEnum } from './Menu';

function SlotGames() {
  return (
    <div id="__nuxt">
      <div id="__layout">
        <div class="wrap nav-close">
          <div class="main">
            <div data-v-16b3034c="" class="gamelist-wrap">
              <div data-v-16b3034c="" class="gamelist-header">
                <div data-v-16b3034c="" class="gamelist-title">
                  <div data-v-16b3034c="" class="title-logo">
                    <img data-v-16b3034c="" src={logo} />
                    <span>UMU</span>
                  </div>
                  <Menu activeCategory={CategoryEnum.SLOT} />
                </div>
              </div>
              <div data-v-16b3034c="" class="gamelist-content">
                <ul data-v-16b3034c="" class="game-list">
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5260&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1685014927_164144.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5261&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1691530447_671514.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5262&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1686847169_549336.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5255&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1654180462_288901.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5253&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1673604188_266934.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5251&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1678195873_981374.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5258&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1663150171_119000.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5259&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1678195996_440845.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5254&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1663150818_966126.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5256&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1654180845_463493.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5252&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1663150437_700198.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5250&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1663149557_136160.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5235&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652296225_548093.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5248&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1678195421_837948.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5225&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298747_672717.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5223&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1687190766_386409.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5226&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1671634452_995893.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5204&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1678195851_927875.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5243&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652297095_461796.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5257&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1688551013_782566.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5231&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298648_269066.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5216&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652295791_498877.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5224&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1687190703_388511.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5218&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652291691_344313.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5223&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1687190766_386409.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5209&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652296149_414993.jpeg" alt="" class="game-card" />
                    </a>
                  </li>

                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5249&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1668699382_168401.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5215&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1687190836_307113.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5219&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1687190799_820189.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5236&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299278_478071.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5230&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652296512_616112.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5245&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652297923_138712.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5214&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298435_116485.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5240&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652291492_680813.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5208&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299396_256609.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5201&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652295937_317095.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5247&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299174_834469.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5234&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652296288_405863.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5401&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652296109_999861.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5211&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652297459_398451.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5202&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652297689_296509.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5222&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298039_513319.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5205&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298204_585194.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5206&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299027_720812.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5207&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298528_706846.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=522&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298238_652310.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5213&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652295583_446344.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5203&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652297853_354185.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5220&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298397_172570.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5227&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298002_755098.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5232&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298170_654986.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5233&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652291444_395600.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5244&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652297970_674550.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                </ul>
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlotGames;
