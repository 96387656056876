export const CategoryEnum = {
    HOT: 'hot',
    FISH: 'fish',
    SLOT: 'slot',
    CASINO: 'casino',
    ARCADE: 'arcade',
  };
  
const Menu = ({activeCategory}) => {
  return (
    <div data-v-16b3034c="" class="gamelist-category">
      <div data-v-16b3034c="" class="categoryPC-block">
        <a data-v-16b3034c="" href="/" class={`category-btn ${activeCategory === CategoryEnum.HOT ? 'active' : ''} hot-btn`}>
          <span data-v-16b3034c="">熱門</span>
        </a>
        <a data-v-16b3034c="" href="fish-games" class={`category-btn ${activeCategory === CategoryEnum.FISH ? 'active' : ''} fish-btn`}>
          <span data-v-16b3034c="">魚機</span>
        </a>
        <a data-v-16b3034c="" href="slot-games" class={`category-btn ${activeCategory === CategoryEnum.SLOT ? 'active' : ''} slot-btn`}>
          <span data-v-16b3034c="">老虎機</span>
        </a>
        <a data-v-16b3034c="" href="casino-games" class={`category-btn ${activeCategory === CategoryEnum.CASINO ? 'active' : ''} casino-btn`}>
          <span data-v-16b3034c="">牌桌</span>
        </a>
        <a data-v-16b3034c="" href="arcade-games" class={`category-btn ${activeCategory === CategoryEnum.ARCADE ? 'active' : ''} arcade-btn`}>
          <span data-v-16b3034c="">押分機</span>
        </a>
      </div>
    </div>
  );
};

export default Menu;
