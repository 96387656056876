import React from 'react';
import logo from './logo.png';
import './App.css';
import Menu, { CategoryEnum } from './Menu';

const HotGames = () => {
  return (
    <div id="__nuxt">
      <div id="__layout">
        <div class="wrap nav-close">
          <div class="main">
            <div data-v-16b3034c="" class="gamelist-wrap">
              <div data-v-16b3034c="" class="gamelist-header">
                <div data-v-16b3034c="" class="gamelist-title">
                  <div data-v-16b3034c="" class="title-logo">
                    <img data-v-16b3034c="" src={logo} />
                    <span>UMU</span>
                  </div>
                  <Menu activeCategory={CategoryEnum.HOT} />
                </div>
              </div>
              <div data-v-16b3034c="" class="gamelist-content">
                <ul data-v-16b3034c="" class="game-list">
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5702&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1695380793_711640.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5260&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1685014927_164144.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5261&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1691530447_671514.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5262&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1686847169_549336.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5255&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1654180462_288901.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5253&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1673604188_266934.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5251&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1678195873_981374.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5256&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1654180845_463493.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5501&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1680102278_184718.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5259&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1678195996_440845.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5254&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1663150818_966126.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5258&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1663150171_119000.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=7707&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652300189_424210.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=7701&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299550_797150.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=7706&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652300016_704100.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5252&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1663150437_700198.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5243&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652297095_461796.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5250&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1663149557_136160.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=3721&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299633_273627.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=3727&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299671_953107.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=7705&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652299591_156934.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5225&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298747_672717.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5217&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652298586_373209.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5204&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1678195851_927875.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5216&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652295791_498877.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5224&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1687190703_388511.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5218&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652291691_344313.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5223&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1687190766_386409.png" alt="" class="game-card" />
                    </a>
                  </li>
                  <li data-v-16b3034c="">
                    <a data-v-16b3034c="" class="game-preview" target="_blank" href="https://ig-game.acewin-stg.cc/DemoLobby/demo.html?gameID=5209&lang=zh-cn">
                      <img data-v-16b3034c="" src="../images/1652296149_414993.jpeg" alt="" class="game-card" />
                    </a>
                  </li>
                </ul>
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotGames;
